import { 
    Button, 
 } from 'react-bootstrap';
 import logo from '../assets/img/logo.white.priemka-pro.ru.png';
 import { Link } from 'react-router-dom';


export default function Header ( props ){
    const sidenavToggle = (val) => props.sidenavToggle(val)
    const onLogout = () => props.onLogout()
    const RecoveryPasswordRequest = () => props.RecoveryPasswordRequest()
    const { 
        username,
        account
    } = props

    const queryParams = new URLSearchParams(window.location.search);
    const webview = queryParams.has('webview')
    if (webview) return null

    return (
        <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark justify-content-between">
            <div className='d-flex flex-row'>
                <Link className="nav-link navbar-brand ps-3" to="/">
                    <img src={logo} className="object-fit-contain" style={{width: 180}} alt="Приёмка Про" />
                    
                </Link>
                
                <Button
                    variant="link"
                    onClick={ sidenavToggle }
                    className='text-light'
                >
                    <i className="fas fa-bars text-white"></i>
                </Button>
            </div>
            <div className='d-flex flex-row'>
                <div className="text-light m-2 d-none d-md-block"><span class="text-secondary">[{account.id}]</span> {account.name} / {username}</div>

                <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i></a>
                        <ul className="dropdown-menu dropdown-menu-end p-3" aria-labelledby="navbarDropdown">
                            {/* <li>
                                <Button
                                    variant="link"
                                    onClick={ RecoveryPasswordRequest }
                                    className="dropdown-item"
                                >
                                    Сменить пароль
                                </Button>
                            </li>
                            <li><hr className="dropdown-divider" /></li> */}
                            <li>
                                <Button
                                    variant="link"
                                    onClick={ onLogout }
                                    className="dropdown-item"
                                >
                                    Выход
                                </Button>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    )
}